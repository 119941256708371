<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-sheet class="dense-inputs d-flex flex-row">
                        <div class="success pt-0 pb-3 px-2 lighten-1 border-rad-sm col-lg-2 col-xs-4 col-md-3 d-flex flex-column justify-space-between">
                            <v-form
                                ref="filterForm"
                                v-model="validForm"
                                lazy-validation
                            >
                                <v-row no-gutters class="pb-1 pt-2">
                                    <v-col class="font-xs font-weight-bold col-6">{{ $t('message.from') }}:</v-col>
                                    <v-col class="font-xs font-weight-bold col-6">{{ $t('message.to') }}:</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-0">
                                    <v-col class="col-6">
                                        <v-select
                                            :items="months"
                                            :label="$t('message.month')"
                                            autocomplete="off"
                                            class="grey lighten-4"
                                            dense
                                            hide-details
                                            item-text="Month.text"
                                            item-value="Month.id"
                                            solo
                                            v-model="filterValues.monthFrom"
                                        />
                                    </v-col>
                                    <v-col class="col-6">
                                        <div class="px-1">
                                            <v-select
                                                :items="months"
                                                :label="$t('message.month')"
                                                autocomplete="off"
                                                class="grey lighten-4"
                                                dense
                                                hide-details
                                                item-text="Month.text"
                                                item-value="Month.id"
                                                solo
                                                v-model="filterValues.monthTo"
                                            />
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters style="padding-bottom: 5px; padding-top: 3px">
                                    <v-col class="font-weight-bold font-xs">{{ $t('message.from') }}:</v-col>
                                    <v-col class="font-weight-bold font-xs">{{ $t('message.to') }}:</v-col>
                                </v-row>
                                <v-row no-gutters class="pt-0">
                                    <v-col class="col-6">
                                        <v-select
                                            :items="years"
                                            :label="$t('message.year')"
                                            :rules="[rules.yearFrom]"
                                            autocomplete="off"
                                            class="grey lighten-4"
                                            dense
                                            hide-details="auto"
                                            item-text="Year.text"
                                            item-value="Year.id"
                                            required
                                            solo
                                            v-model="filterValues.yearFrom"
                                            @change="validateYear('yearFrom')"
                                        />
                                    </v-col>
                                    <v-col class="col-6">
                                        <div class="px-1">
                                            <v-select
                                                :items="years"
                                                :label="$t('message.year')"
                                                :rules="[rules.yearTo]"
                                                autocomplete="off"
                                                class="grey lighten-4"
                                                dense
                                                hide-details="auto"
                                                item-text="Year.text"
                                                item-value="Year.id"
                                                solo
                                                v-model="filterValues.yearTo"
                                                @change="validateYear('yearTo')"
                                            />
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-0">
                                    <v-col class="d-flex flex-row align-center">
                                        <v-checkbox
                                            :readonly="disableYearOnYear"
                                            :false-value="0"
                                            :true-value="1"
                                            :class="disableYearOnYear ? 'checkbox-disabled' : 'black-checkbox-outline'"
                                            color="info"
                                            dense
                                            hide-details="auto"
                                            v-model="filterValues.yearOnYear"
                                        />
                                        <span :class="disableYearOnYear ? 'year-on-year-disabled mt-3' : 'mt-3'">{{ $t('message.yearOnYearResults') }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="py-1">
                                    <v-col cols="12" class="font-weight-bold font-xs">{{ $t('message.value') }}:</v-col>
                                    <v-select
                                        :items="valueOptions"
                                        :placeholder="$t('message.selectOne')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="filterValues.value"
                                    />
                                </v-row>
                                <v-row no-gutters class="py-1">
                                    <v-col cols="12" class="font-weight-bold font-xs">{{ $t('message.salesType') }}:</v-col>
                                    <v-select
                                        :items="filteredSalesTypes"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Salestype.title"
                                        item-value="Salestype.id"
                                        multiple
                                        solo
                                        v-model="filterValues.salesType"
                                    />
                                </v-row>
                                <v-row no-gutters class="py-1">
                                    <v-col cols="12" class="font-weight-bold font-xs">{{ $t('message.resultsBy') }}:</v-col>
                                    <v-select
                                        :items="resultsByOptions"
                                        :placeholder="$t('message.selectOne')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="filterValues.resultBy"
                                        @change="getResultByFilterOptions"
                                    />
                                    <v-col cols="12" class="d-flex flex-column">
                                        <div class="py-1" v-if="resultByFilterQty != 'All'">{{ $t('message.selected') + ' ' + getResultByText(filterValues.resultBy) + ': ' +  resultByFilterQty }} </div>
                                        <v-btn small @click="openSelectResultByFilterDialog()" :class="'col-6 py-1' + ( resultByFilterQty == 'All' ? ' mt-2' : '')">{{ $t('message.select')}}</v-btn>
                                    </v-col>
                                    <v-col cols="12" class="d-flex flex-row align-center mt-2"  v-if="filterValues.resultBy == 'supplier_id'">
                                        <v-checkbox
                                            :true-value="1"
                                            class="black-checkbox-outline mt-0"
                                            dense
                                            hide-details="auto"
                                            solo
                                            v-model="filterValues.groupResultBySameSupplier"
                                        />
                                        <span>{{ $t('message.showGroupedSuppliers') }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="py-3" v-if="false">
                                    <v-col cols="12" class="font-weight-bold font-xs">{{ $t('message.filter') }}:</v-col>
                                    <v-select
                                        :items="filterByOptions"
                                        :placeholder="$t('message.all')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="filterValues.filterBy"
                                    />
                                </v-row>
                                <v-row no-gutters class="py-3">
                                    <v-col cols="12" class="font-weight-bold font-xs">{{ $t('message.basedOn') }}:</v-col>
                                    <v-select
                                        :items="basedOnOptions"
                                        :placeholder="$t('message.none')"
                                        :rules="[rules.basedOn]"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="filterValues.basedOn"
                                        @change="getBasedOnFilterOptions()"
                                    />
                                    <v-col cols="12" class="d-flex flex-column pb-3" v-if="filterValues.basedOn != null">
                                        <div class="py-1" v-if="basedOnFilterQty != 'All'">{{ $t('message.selected') + ': ' +  basedOnFilterQty }} </div>
                                        <v-btn small @click="openSelectBasedOnFilterDialog()" :class="'col-6 py-1' + ( basedOnFilterQty == 'All' ? ' mt-2' : '')">{{ $t('message.select')}}</v-btn>
                                    </v-col>
                                    <v-col cols="12" class="d-flex flex-row align-center" v-if="filterValues.basedOn == 'supplier_id'">
                                        <v-checkbox
                                            :true-value="1"
                                            class="black-checkbox-outline mt-0"
                                            dense
                                            hide-details="auto"
                                            solo
                                            v-model="filterValues.groupBasedOnSameSupplier"
                                        />
                                        <span>{{ $t('message.showGroupedSuppliers') }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="py-1" v-if="false">
                                    <v-col cols="12" class="font-weight-bold font-xs">{{ $t('message.chartType') }}:</v-col>
                                    <v-select
                                        :items="chartTypes"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="filterValues.chartType"
                                    />
                                </v-row>
                                <v-row no-gutters class="py-1" v-if="false">
                                    <v-col cols="12" class="font-weight-bold font-xs">{{ $t('message.additionalData') }}:</v-col>
                                    <template>
                                        <v-checkbox
                                            :label="$t('message.showOtherValues')"
                                            class="black-checkbox-outline"
                                            color="info"
                                            dense
                                            hide-details="auto"
                                        />
                                    </template>
                                    <template>
                                        <v-checkbox
                                            :label="$t('message.showTotalValues')"
                                            class="black-checkbox-outline"
                                            color="info"
                                            dense
                                            hide-details="auto"
                                        />
                                    </template>
                                </v-row>
                            </v-form>
                            <v-row no-gutters class="pt-5">
                                <v-col cols="12" class="d-flex flex-row">
                                    <v-btn color="grey white--text" block small @click="fetchReport()">
                                        <span v-if="loading.fetch">{{ $t('message.fetching') }}</span>
                                        <span v-else>{{ $t('message.fetch') }}</span>
                                    </v-btn>
                                    <v-btn :loading="loading.clear" color="grey white--text" block small class="ml-2" @click="resetFilters()">{{ $t('message.clearAll') }}</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pt-0 pb-3 px-2 ml-3 pr-5 lighten-1 border-rad-sm col-lg-10 col-xs-8 col-md-9" :style="'height: ' + reportHeight + 'px'">
                            <v-overlay
                                :value="loading.fetch"
                                absolute
                                opacity="0.15"
                            >
                                <v-row>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                            color="primary"
                                            indeterminate
                                            size="40"
                                            width="6"
                                        />
                                    </v-col>
                                </v-row>
                            </v-overlay>
                            <v-row :style="'height: ' + reportHeight + 'px'" v-if="reportData.length == 0">
                                <v-col cols="12" class="d-flex flex-column justify-center align-center grey--text"><h4>{{ $t('message.noResultsFetched') }}</h4></v-col>
                            </v-row>
                            <ListingTabularV4
                                :report-data="reportData"
                                :report-hash="reportHash"
                                :table-options="tableOptions"
                                :table-titles="tableTitles"
                                v-if="reportData.length > 0 && filterValues.chartType == 'listing-tabular'"
                            />
                            <BarChartMonthly
                                :report-data="reportData"
                                :table-options="tableOptions"
                                :table-titles="tableTitles"
                                v-if="reportData.length > 0 && filterValues.chartType == 'bar-chart-monthly'"
                            />
                            <PieChartTvRvSummary
                                :report-data="reportData"
                                :table-options="tableOptions"
                                :table-titles="tableTitles"
                                v-if="reportData.length > 0 && filterValues.chartType == 'pie-chart-tv-rv-summary'"
                            />
                        </div>
                    </v-sheet>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            width="600px"
            v-model="selectResultByFilterDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.selectOptionsForFiltering') }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-row no-gutters class="d-flex flex-row align-center">
                        <v-col>
<!--                            <h5>{{ $t('message.selected') }}</h5>-->
                            <v-data-table
                                :headers="filterHeaders"
                                :height="(reportHeight - 200).toString() + 'px'"
                                :items-per-page="-1"
                                :items="resultsByFilterOptions"
                                :search="resultByFilterSearchTerm"
                                :sort-by="'text'"
                                :no-data-text="$t('message.allOptionsRemoved')"
                                class="filter-table"
                                dense
                                fixed-header
                                hide-default-footer
                                item-key="value"
                                show-select
                                v-model="resultByFilterOptionsToAdd"
                            >
                                <template v-slot:header.text>
                                    <div class="d-flex flex-row align-center">
                                        <v-text-field
                                            :label="$t('message.filterResults')"
                                            dense
                                            hide-details="auto"
                                            prepend-inner-icon="mdi-filter-outline"
                                            solo
                                            v-model="resultByFilterSearchTerm"
                                        ></v-text-field>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="selectResultByFilterDialog = false"
                    >{{ $t('message.close') }}
                    </v-btn>
                    <v-btn
                        class="ml-3"
                        color="info"
                        small
                        @click="addResultByFilter()"
                    >{{ $t('message.select') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            width="600px"
            v-model="selectBasedOnFilterDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.selectOptionsForFiltering') }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-row no-gutters class="d-flex flex-row align-center">
                        <v-col>
                            <h5>{{ $t('message.selected') }}</h5>
                            <v-data-table
                                :headers="filterHeaders"
                                :height="(reportHeight - 200).toString() + 'px'"
                                :items-per-page="-1"
                                :items="basedOnFilterOptions"
                                :search="basedOnFilterSearchTerm"
                                :sort-by="'text'"
                                :no-data-text="$t('message.allOptionsRemoved')"
                                class="filter-table"
                                dense
                                fixed-header
                                hide-default-footer
                                item-key="value"
                                show-select
                                v-model="basedOnFilterOptionsToAdd"
                            >
                                <template v-slot:header.text>
                                    <div class="d-flex flex-row align-center">
                                        <v-text-field
                                            :label="$t('message.filterResults')"
                                            :value="basedOnFilterSearchTerm"
                                            dense
                                            hide-details="auto"
                                            prepend-inner-icon="mdi-filter-outline"
                                            solo
                                            v-model="basedOnFilterSearchTerm"
                                        ></v-text-field>
<!--                                        <v-btn class="ml-3" @click="removeBasedOnFilter()">{{ $t('message.remove') }}</v-btn>-->
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
<!--                        <div class="mx-2"><v-icon large color="success">mdi-swap-horizontal</v-icon></div>-->
<!--                        <v-col>-->
<!--                            <h5>{{ $t('message.deselected') }}</h5>-->
<!--                            <v-data-table-->
<!--                                :headers="filterHeaders"-->
<!--                                :height="(reportHeight - 200).toString() + 'px'"-->
<!--                                :items-per-page="-1"-->
<!--                                :items="deselectedBasedOnFilterOptions"-->
<!--                                :search="deselectedBasedOnFilterSearchTerm"-->
<!--                                :sort-by="'text'"-->
<!--                                :no-data-text="''"-->
<!--                                class="filter-table"-->
<!--                                dense-->
<!--                                fixed-header-->
<!--                                hide-default-footer-->
<!--                                item-key="value"-->
<!--                                show-select-->
<!--                                v-model="basedOnFilterOptionsToAdd"-->
<!--                            >-->
<!--                                <template v-slot:header.text>-->
<!--                                    <div class="d-flex flex-row align-center">-->
<!--                                        <v-text-field-->
<!--                                            :label="$t('message.filterResults')"-->
<!--                                            :value="deselectedBasedOnFilterSearchTerm"-->
<!--                                            dense-->
<!--                                            hide-details="auto"-->
<!--                                            prepend-inner-icon="mdi-filter-outline"-->
<!--                                            solo-->
<!--                                            @change="deselectedBasedOnFilterSearchTerm = $event"-->
<!--                                        ></v-text-field>-->
<!--                                        <v-btn class="ml-3" @click="addBasedOnFilter()">{{ $t('message.add') }}</v-btn>-->
<!--                                    </div>-->
<!--                                </template>-->
<!--                            </v-data-table>-->
<!--                        </v-col>-->
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="selectBasedOnFilterDialog = false"
                    >{{ $t('message.close') }}
                    </v-btn>
                    <v-btn
                        class="ml-3"
                        color="info"
                        small
                        @click="addBasedOnFilter()"
                    >{{ $t('message.add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapGetters, mapActions} from "vuex";
import _ from 'lodash';
import {log} from "Helpers/helpers";

const BarChartMonthly = () => import("Components/Appic/MarketingReport/BarChartMonthly");
const PieChartTvRvSummary = () => import("Components/Appic/MarketingReport/PieChartTvRvSummary");
const ListingTabularV4 = () => import("Components/Appic/MarketingReport/ListingTabularV4");

export default {
    name: "MarketingReportV5",
    components: {PieChartTvRvSummary, BarChartMonthly, ListingTabularV4 },
    title: '',
    data(){
        return {
            basedOnFilterOptionsToAdd: [],
            basedOnFilterOptionsToRemove: [],
            basedOnFilterOptions: [],
            basedOnFilterSearchTerm: null,
            basedOnOptions: [
                {
                    text: this.$t('message.destination'),
                    value: 'destination_id'
                },
                {
                    text: this.$t('message.origin'),
                    value: 'origin_id'
                },
                {
                    text: this.$t('message.buyer'),
                    value: 'customer_id'
                },
                {
                    text: this.$t('message.marketSegment'),
                    value: 'marketsegment_id'
                },
                {
                    text: this.$t('message.product'),
                    value: 'productgroup_id'
                },
                {
                    text: this.$t('message.salesColleague'),
                    value: 'salescontact_id'
                },
                {
                    text: this.$t('message.supplier'),
                    value: 'supplier_id'
                },
                {
                    text: this.$t('message.species'),
                    value: 'species_id'
                },
            ],
            chartTypes: [
                {
                    header: this.$t('message.listing')
                },
                {
                    value: 'listing-tabular',
                    text: this.$t('message.tabular')
                },
                {
                    header: this.$t('message.barChart')
                },
                {
                    value: 'bar-chart-monthly',
                    text: this.$t('message.monthly')
                },
                {
                    header: this.$t('message.pieChart')
                },
                {
                    value: 'pie-chart-tv-rv-summary',
                    text: this.$t('message.tvRvSummary')
                }
            ],
            deselectedBasedOnFilterSearchTerm: null,
            deselectedResultByFilterSearchTerm: null,
            deselectedBasedOnFilterOptions: [],
            deselectedResultsByFilterOptions: [],
            filterByOptions: [
                {
                    text: this.$t('message.all'),
                    value: null
                },
                {
                    text: this.$t('message.topFive'),
                    value: 'top_5'
                },
                {
                    text: this.$t('message.topTen'),
                    value: 'top_10'
                },
                {
                    text: this.$t('message.topTwentyFive'),
                    value: 'top_25'
                },
            ],
            resultByFilterOptionsToAdd: [],
            resultByFilterOptionsToRemove: [],
            filterValues: {
                chartType: 'listing-tabular',
                basedOn: null,
                filterBy: null,
                groupBasedOnSameSupplier: null,
                groupResultBySameSupplier: 1,
                monthFrom: 1,
                monthTo: 12,
                resultBy: 'supplier_id',
                salesType: [1,2,3,5],
                value: 'cost',
                yearFrom: new Date().getFullYear(),
                yearTo:  new Date().getFullYear(),
                yearOnYear: 0
            },
            loader: false,
            loading: {
                clear: false,
                fetch: false,
                items: false
            },
            reportHash: null,
            reportData: [],
            reportHeight: '500',
            resultsByFilterOptions: [],
            resultByFilterSearchTerm: null,
            resultsByOptions: [
                {
                    text: this.$t('message.destination'),
                    value: 'destination_id'
                },
                {
                    text: this.$t('message.origin'),
                    value: 'origin_id'
                },
                {
                    text: this.$t('message.buyer'),
                    value: 'customer_id'
                },
                {
                    text: this.$t('message.marketSegment'),
                    value: 'marketsegment_id'
                },
                {
                    text: this.$t('message.product'),
                    value: 'productgroup_id'
                },
                {
                    text: this.$t('message.salesColleague'),
                    value: 'salescontact_id'
                },
                {
                    text: this.$t('message.supplier'),
                    value: 'supplier_id'
                },
                {
                    text: this.$t('message.species'),
                    value: 'species_id'
                },
            ],
            rules: {
                basedOn: v => v == this.filterValues.resultBy ? this.$t('message.basedOnVsResultBy') : true,
                yearFrom: true,
                yearTo: true
            },
            selectedBasedOnFilterSearchTerm: null,
            selectedResultByFilterSearchTerm: null,
            selectedBasedOnFilterOptions: [],
            selectedResultsByFilterOptions: [],
            selectBasedOnFilterDialog: false,
            selectResultByFilterDialog: false,
            tableOptions: {
                // filterBy: 'top_25',
                filterBy: 'null',
                groupBy: null,
                sortBy: ['result_value'],
                sortDesc: [true],
                yearOnYear: 0,
            },
            tableTitles: {
                currentDate: new Date().toISOString().substr(0,10),
                dateRange: null,
                filter: null,
                // filterBy: 'top_25',
                filterBy: null,
                groupBy: null,
                monthFrom: null,
                monthTo: null,
                resultBy: null,
                salesType: this.$t('message.all'),
                sortColumn: null,
                sortDescending: null,
                total: 0,
                value: this.$t('message.tradingValue'),
                yearFrom: null,
                yearOnYear: 1,
                yearTo: null
            },
            tabularMode: 'data-table',
            validForm: false,
            valueOptions: [
                {
                    text: this.$t('message.invoicedSales'),
                    value: 'invoicedSales'
                },
                {
                    text: this.$t('message.intakeSales'),
                    value: 'intakeSales'
                },
                {
                    text: this.$t('message.purchaseCost'),
                    value: 'cost'
                },
                {
                    text: this.$t('message.grossMargin'),
                    value: 'grossMargin'
                }
            ]
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters([
            'months',
            'salesTypes'
        ]),
        basedOnFilterQty() {
            let qty = ''
            // if(this.deselectedBasedOnFilterOptions.length == 0){
            //     qty = this.$t('message.all')
            // } else if(this.deselectedBasedOnFilterOptions.length > 0 && this.deselectedBasedOnFilterOptions.length < this.basedOnFilterOptions.length){
            //     qty = this.selectedBasedOnFilterOptions.length
            // } else {
            //     qty = this.$t('message.none')
            // }
            if( this.selectedBasedOnFilterOptions.length == this.basedOnFilterOptions.length ) {
                qty = this.$t('message.all')
            } else if( this.selectedBasedOnFilterOptions.length == 0 ){
                qty = this.$t('message.all')
            } else {
                qty = this.selectedBasedOnFilterOptions.length
            }
            return qty
        },
        disableYearOnYear() {
            if(this.filterValues.yearFrom == this.filterValues.yearTo) return true
            return false
        },
        filterHeaders() {
            return [
                {
                    id: 1,
                    text: null,
                    value: 'text',
                    searchable: false,
                    sortable: false
                }
            ]
        },
        filteredSalesTypes() {
            let types = []
            // types.push({
            //     Salestype: {
            //         id: 998,
            //         title: this.$t('message.allExceptWhSale'),
            //         code: null
            //     }
            // })
            // types.push({
            //     Salestype: {
            //         id: 999,
            //         title: this.$t('message.allIncludeWhSale'),
            //         code: null
            //     }
            // })
            this.salesTypes.forEach(s => {
                if([1,2,3,5,99].includes(s.Salestype.id)){
                    types.push(s)
                }
            })
            return types
        },
        resultByFilterQty() {
            let qty = ''
            // if(this.deselectedResultsByFilterOptions.length == 0){
            //     qty = this.$t('message.all')
            // } else if(this.deselectedResultsByFilterOptions.length > 0 && this.deselectedResultsByFilterOptions.length < this.resultsByFilterOptions.length){
            //     qty = this.selectedResultsByFilterOptions.length
            // } else {
            //     qty = this.$t('message.none')
            // }
            if( this.selectedResultsByFilterOptions.length == this.resultsByFilterOptions.length ) {
                qty = this.$t('message.all')
            } else if( this.selectedResultsByFilterOptions.length == 0 ){
                qty = this.$t('message.all')
            } else {
                qty = this.selectedResultsByFilterOptions.length
            }
            return qty
        },
        years() {
            let array = []
            let currentYear = new Date().getFullYear()
            let startYear = 2010 //start of TMS?
            while(currentYear >= startYear){
                array.push({Year: {text: currentYear.toString(), id: currentYear}})
                currentYear = currentYear - 1
            }
            return array
        }
    },
    methods: {
        ...mapActions('report',{
            getMarketingReport: 'getMarketingReport',
            getMarketingReportFilters: 'getMarketingReportFilters'
        }),
        addBasedOnFilter() {
            // this.basedOnFilterOptionsToAdd.forEach(option => {
            //     const index = this.deselectedBasedOnFilterOptions.findIndex(obj => obj.value == option.value)
            //     this.deselectedBasedOnFilterOptions = [...this.deselectedBasedOnFilterOptions.slice(0, index), ...this.deselectedBasedOnFilterOptions.slice(index + 1)]
            // })
            this.selectedBasedOnFilterOptions = []
            this.selectedBasedOnFilterOptions.push(...this.basedOnFilterOptionsToAdd)
            this.selectedBasedOnFilterOptions.sort((a,b)=>{
                return a['text'].localeCompare(b['text'])
            })
            this.closeSelectBasedOnFilterDialog()
            // this.basedOnFilterOptionsToAdd = []
        },
        addResultByFilter() {
            // this.resultByFilterOptionsToAdd.forEach(option => {
            //     const index = this.deselectedResultsByFilterOptions.findIndex(obj => obj.value == option.value)
            //     this.deselectedResultsByFilterOptions = [...this.deselectedResultsByFilterOptions.slice(0, index), ...this.deselectedResultsByFilterOptions.slice(index + 1)]
            // })
            // log(this.resultByFilterOptionsToAdd)
            this.selectedResultsByFilterOptions = []
            this.selectedResultsByFilterOptions.push(...this.resultByFilterOptionsToAdd)
            this.selectedResultsByFilterOptions.sort((a,b)=>{
                return a['text'].localeCompare(b['text'])
            })
            this.closeSelectResultByFilterDialog()
            // this.resultByFilterOptionsToAdd = []
            // log(this.selectedResultsByFilterOptions)
        },
        closeSelectBasedOnFilterDialog() {
            this.selectBasedOnFilterDialog = false
        },
        closeSelectResultByFilterDialog() {
            this.selectResultByFilterDialog = false
        },
        fetchReport() {
            if(this.$refs.filterForm.validate()) {
                let selectedResultsByFilterOptions = null
                let selectedBasedOnFilterOptions = null

                // if(this.selectedResultsByFilterOptions.length > 0 && this.resultsByFilterOptions.length > this.deselectedResultsByFilterOptions.length && this.deselectedResultsByFilterOptions.length > 0){
                //     selectedResultsByFilterOptions = this.selectedResultsByFilterOptions.map(el => el.value)
                // } else if(this.resultsByFilterOptions.length == this.deselectedResultsByFilterOptions.length){
                //     selectedResultsByFilterOptions = []
                // }

                if(this.selectedResultsByFilterOptions.length > 0){
                    selectedResultsByFilterOptions = this.selectedResultsByFilterOptions.map(el => el.value)
                } else {
                    selectedResultsByFilterOptions = []
                }

                if(this.filterValues.basedOn != null) {
                    // if (this.selectedBasedOnFilterOptions.length > 0 && this.basedOnFilterOptions.length > this.deselectedBasedOnFilterOptions.length && this.deselectedBasedOnFilterOptions.length > 0) {
                    //     selectedBasedOnFilterOptions = this.selectedBasedOnFilterOptions.map(el => el.value)
                    // } else if (this.basedOnFilterOptions.length == this.deselectedBasedOnFilterOptions.length) {
                    //     selectedBasedOnFilterOptions = []
                    // }
                    if (this.selectedBasedOnFilterOptions.length > 0) {
                        selectedBasedOnFilterOptions = this.selectedBasedOnFilterOptions.map(el => el.value)
                    } else if (this.basedOnFilterOptions.length == this.deselectedBasedOnFilterOptions.length) {
                        selectedBasedOnFilterOptions = []
                    }
                }

                let payload = {
                    filterValues: this.filterValues,
                    resultByFilters: selectedResultsByFilterOptions,
                    basedOnFilters: selectedBasedOnFilterOptions
                }
                this.loading.fetch = true
                this.getMarketingReport(payload)
                    .then((data) => {
                        this.reportData = data.reportData
                        this.reportHash = data.reportHash
                        this.generateTitles()
                        this.loading.fetch = false
                        //update tableOptions
                        if (this.filterValues.basedOn != null) {
                            this.tableOptions.groupBy = 'group_position'
                            this.tableOptions.sortBy = ['group_position', 'result_value'],
                                this.tableOptions.sortDesc = [true, true]
                        } else {
                            this.tableOptions.groupBy = null
                            this.tableOptions.sortBy = ['result_value'],
                                this.tableOptions.sortDesc = [true]
                        }
                        this.tableOptions.filterBy = this.filterValues.filterBy
                        this.tableOptions.yearOnYear = this.filterValues.yearOnYear
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.marketingReportNotFetched'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.fetch = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.basedOnVsResultBy'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                this.loading.fetch = false
            }
        },
        handleResize() {
            this.reportHeight = window.innerHeight - (100);
        },
        generateTitles () {
            return new Promise((resolve, reject) => {
                try {
                    //get date range
                    let dateRange = ''
                    if (this.filterValues.yearOnYear == 1) {
                        if (this.filterValues.yearFrom) {
                            dateRange += 'From ' + this.filterValues.monthFrom + '-' + this.filterValues.monthTo
                            dateRange += '/' + this.filterValues.yearFrom
                            this.tableTitles.yearFrom = this.filterValues.yearFrom
                        }
                        if (this.filterValues.yearTo) {
                            dateRange += ' to ' + this.filterValues.monthFrom + '-' + this.filterValues.monthTo
                            dateRange += '/' + this.filterValues.yearTo
                            this.tableTitles.yearTo = this.filterValues.yearTo
                        }
                    } else {
                        if (this.filterValues.monthFrom) {
                            dateRange += 'From ' + this.filterValues.monthFrom
                        }
                        if (this.filterValues.yearFrom) {
                            dateRange += '/' + this.filterValues.yearFrom
                            this.tableTitles.yearFrom = this.filterValues.yearFrom
                        }
                        if (this.filterValues.monthTo) {
                            dateRange += ' to ' + this.filterValues.monthTo
                        }
                        if (this.filterValues.yearTo) {
                            dateRange += '/' + this.filterValues.yearTo
                            this.tableTitles.yearTo = this.filterValues.yearTo
                        }
                    }
                    this.tableTitles.monthFrom = this.filterValues.monthFrom
                    this.tableTitles.monthTo = this.filterValues.monthTo
                    this.tableTitles.dateRange = dateRange

                    //get sales types
                    let salesType = this.salesTypes.find(type => type.Salestype.id == this.filterValues.salesType)
                    if (salesType) {
                        this.tableTitles.salesType = salesType.Salestype.title
                    } else {
                        this.tableTitles.salesType = this.$t('message.all')
                    }

                    //get value type
                    let valueType = this.valueOptions.find(option => option.value == this.filterValues.value)
                    if (valueType) {
                        this.tableTitles.value = valueType.text
                    } else {
                        this.tableTitles.value = this.$t('message.tradingValue')
                    }

                    //get result by
                    let resultBy = this.resultsByOptions.find(option => option.value == this.filterValues.resultBy)
                    if (resultBy) {
                        this.tableTitles.resultBy = resultBy.text
                    } else {
                        this.tableTitles.resultBy = this.$t('message.supplier')
                    }

                    //get filter by
                    let filterBy = this.filterByOptions.find(option => option.value == this.filterValues.filterBy)
                    if (filterBy) {
                        this.tableTitles.resultBy = this.tableTitles.resultBy + ' (' + filterBy.text + ')'
                    }

                    //get based on
                    let basedOn = this.basedOnOptions.find(option => option.value == this.filterValues.basedOn)
                    if (basedOn) {
                        this.tableTitles.groupBy = basedOn.text
                    } else {
                        this.tableTitles.groupBy = null
                    }

                    //get year-on-year
                    if (this.filterValues.yearOnYear == 1) {
                        this.tableTitles.yearOnYear = 1
                    } else {
                        this.tableTitles.yearOnYear = 0
                    }

                    //get total
                    let total = 0;
                    if (this.filterValues.yearOnYear == 1) {
                        let years = []
                        let endYear = this.filterValues.yearTo
                        let currentYear = this.filterValues.yearFrom
                        while (currentYear <= endYear) {
                            years.push('Y' + currentYear.toString())
                            currentYear = currentYear + 1
                        }
                        this.reportData.forEach(data => {
                            years.forEach(y => {
                                total += data[y]
                            })
                        })
                        this.tableTitles.total = total
                    } else {
                        this.reportData.forEach(data => {
                            total += data.result_value
                        })
                        this.tableTitles.total = total
                    }
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        getResultByFilterOptions () {
            this.loading.fetch = true
            let filterValues = _.cloneDeep(this.filterValues)
            delete filterValues.basedOn
            delete filterValues.resultBy
            delete filterValues.chartType
            delete filterValues.filterBy
            delete filterValues.basedOn

            let payload = {
                filter: this.filterValues.resultBy,
                filterValues: filterValues
            }
            this.getMarketingReportFilters(payload)
                .then((data) => {
                    this.resultsByFilterOptions = data
                    this.selectedResultsByFilterOptions = data
                    this.deselectedResultsByFilterOptions = []
                    this.resultByFilterOptionsToAdd = []
                    this.resultByFilterOptionsToRemove = []
                    this.generateTitles()
                        .then(() => {
                            this.loading.fetch = false
                        })
                        .catch(() => {
                            this.loading.fetch = false
                        })
                })
                .catch( error => {
                    log(error)
                    this.loading.fetch = false
                })
        },
        getResultByText (resultBy) {
            let resultByOption = this.resultsByOptions.find(o => o.value == resultBy)
            if(resultByOption){
                return resultByOption.text != 'Species' ? resultByOption.text + 's' : resultByOption.text
            }
            return ''
        },
        getBasedOnFilterOptions () {
            if(this.filterValues.basedOn) {
                let filterValues = _.cloneDeep(this.filterValues)
                delete filterValues.basedOn
                delete filterValues.resultBy
                delete filterValues.chartType
                delete filterValues.filterBy
                delete filterValues.basedOn

                let payload = {
                    filter: this.filterValues.basedOn,
                    filterValues: filterValues
                }
                this.getMarketingReportFilters(payload)
                    .then((data) => {
                        this.basedOnFilterOptions = data
                        this.selectedBasedOnFilterOptions = data
                        this.deselectedBasedOnFilterOptions = []
                        this.basedOnFilterOptionsToAdd = []
                        this.basedOnFilterOptionsToRemove = []
                        this.generateTitles()
                    })
                    .catch(error => log(error))
            } else {
                this.basedOnFilterOptions = []
                this.selectedBasedOnFilterOptions = []
                this.deselectedBasedOnFilterOptions = []
                this.basedOnFilterOptionsToAdd = []
                this.basedOnFilterOptionsToRemove = []
                this.generateTitles()
            }
        },
        openSelectBasedOnFilterDialog () {
            this.selectBasedOnFilterDialog = true
        },
        openSelectResultByFilterDialog () {
            this.selectResultByFilterDialog = true
        },
        removeBasedOnFilter() {
            this.basedOnFilterOptionsToRemove.forEach(option => {
                const index = this.selectedBasedOnFilterOptions.findIndex(obj => obj.value == option.value)
                this.selectedBasedOnFilterOptions = [...this.selectedBasedOnFilterOptions.slice(0, index), ...this.selectedBasedOnFilterOptions.slice(index + 1)]
            })
            this.deselectedBasedOnFilterOptions.push(...this.basedOnFilterOptionsToRemove)
            this.deselectedBasedOnFilterOptions.sort((a,b)=>{
                return a['text'].localeCompare(b['text'])
            })
            this.basedOnFilterOptionsToRemove = []
        },
        removeResultByFilter() {
            this.resultByFilterOptionsToRemove.forEach(option => {
                const index = this.selectedResultsByFilterOptions.findIndex(obj => obj.value == option.value)
                this.selectedResultsByFilterOptions = [...this.selectedResultsByFilterOptions.slice(0, index), ...this.selectedResultsByFilterOptions.slice(index + 1)]
            })
            this.deselectedResultsByFilterOptions.push(...this.resultByFilterOptionsToRemove)
            this.deselectedResultsByFilterOptions.sort((a,b)=>{
                return a['text'].localeCompare(b['text'])
            })
            this.resultByFilterOptionsToRemove = []
        },
        resetFilters () {
            this.filterValues.chartType = 'listing-tabular'
            this.filterValues.basedOn = null
            // this.filterValues.filterBy = 'top_25'
            this.filterValues.filterBy = null
            this.filterValues.monthFrom = 1
            this.filterValues.monthTo = 12
            this.filterValues.resultBy = 'supplier_id'
            this.filterValues.salesType = [1,2,3,5]
            this.filterValues.value = 'cost'
            this.filterValues.yearFrom = new Date().getFullYear()
            this.filterValues.yearTo =  new Date().getFullYear()
            this.filterValues.groupBasedOnSameSupplier = null
            this.filterValues.groupResultBySameSupplier = 1
        },
        validateYear (rule) {
            if(rule == 'yearFrom') this.rules.yearFrom = this.$t('message.mustBeEarlierEqualThanToYear')
            if(rule == 'yearTo') this.rules.yearTo = this.$t('message.mustBeLaterThanEqualFromYear')
            if(this.filterValues.yearTo != 0 && this.filterValues.yearFrom != 0 && this.filterValues.yearTo >= this.filterValues.yearFrom) {
                this.rules.yearFrom = true
                this.rules.yearTo = true
            } else if (this.filterValues.yearTo != 0 && this.filterValues.yearFrom != 0 && this.filterValues.yearTo < this.filterValues.yearFrom) {
                this.rules.yearFrom = true
            }
            this.getResultByFilterOptions()
        }
    },
    watch: {
        'filterValues.yearFrom': function(newVal) {
            // this.filterValues.yearTo = newVal
            if( newVal < this.filterValues.yearTo ) {
                this.filterValues.yearOnYear = 1
            } else if(newVal == this.filterValues.yearTo){
                this.filterValues.yearOnYear = 0
            }
        },
        'filterValues.yearTo': function(newVal) {
            if(newVal == this.filterValues.yearFrom) {
                this.filterValues.yearOnYear = 0
            } else if(newVal > this.filterValues.yearFrom){
                this.filterValues.yearOnYear = 1
            }
        },
        'filterValues.yearOnYear': function(newVal) {
            if(newVal != 1){
                this.filterValues.yearTo = this.filterValues.yearFrom
            }
        },
        'filterValues.resultBy': function(newVal) {
            if(newVal == 'supplier_id'){
                this.filterValues.groupResultBySameSupplier = 1
            } else {
                this.filterValues.groupResultBySameSupplier = null
            }
        },
        'filterValues.basedOn': function(newVal) {
            if(newVal == 'supplier_id'){
                this.filterValues.groupBasedOnSameSupplier = 1
            } else {
                this.filterValues.groupBasedOnSameSupplier = null
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted(){
        this.handleResize()
        this.$title = this.$t('message.titles.marketingReport')
        if(this.filterValues.resultBy != null){
            this.getResultByFilterOptions()
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
v-input--selection-controls__input {
    margin-right: 0px !important;
}
.v-radio.theme--light {
    margin-right: 7px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.v-radio.theme--light label{
    font-size: 0.8rem !important;
}
.black-checkbox-outline .v-icon {
    color: black !important;
}
.filter-table .v-data-table__wrapper thead th.text-start  {
    border-bottom: 0px !important;
}
.filter-table .v-data-table__wrapper tbody td.text-start  {
    border-bottom: unset !important;
}
.filter-table .v-data-table__wrapper thead th:first-child {
    width: 10px !important;
    padding-right: 0px;
    padding-left: 0px;
}
.filter-table .v-data-table__wrapper tbody td:first-child {
    width: 10px !important;
    padding-right: 0px;
    padding-left: 0px;
}
.v-data-table .v-data-table-header th {
    white-space: nowrap;
}
.checkbox-disabled label {
    color: darkgrey !important;
}
.year-on-year-disabled {
    color: darkgrey !important;
}
</style>